import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useParams } from "react-router-dom";
import Services from "../../api/Services";
import Contact from "./contact";
import Footer from "../../components/footer/Footer";
import "./style.scss";

const ServiceSinglePage = (props) => {
  const { slug } = useParams();

  const serviceDetails = Services.find((item) => item.slug === slug);

  return (
    <Fragment>
      <Navbar hclass={"header-style-2"} />
      <PageTitle pageTitle={serviceDetails.sTitle} pagesub={"Service"} />
      <div className="service-single-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="service-single-wrap">
                <div className="service-single-item">
                  <div className="service-single-main-img">
                    <img src={serviceDetails.sSimg} alt="" />
                  </div>
                  <div className="service-single-title">
                    <h3>{serviceDetails.sTitle}</h3>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Metus dis posuere amet tincidunt commodo, velit. Ipsum, hac
                    nibh fermentum nisi, platea condimentum cursus velit dui.
                    Massa volutpat odio facilisis purus sit elementum. Non, sed
                    velit dictum quam. Id risus pharetra est, at rhoncus, nec
                    ullamcorper tincidunt. Id aliquet duis sollicitudin diam,
                    elit sit. Et nisi in libero facilisis sed est. Elit
                    curabitur amet risus bibendum. Posuere et eget orci, tempor
                    enim.
                  </p>
                  <p>
                    Hac nibh fermentum nisi, platea condimentum cursus velit
                    dui. Massa volutpat odio facilisis purus sit elementum. Non,
                    sed velit dictum quam. Id risus pharetra est, at rhoncus,
                    nec ullamcorper tincidunt. Id aliquet duis sollicitudin
                    diam, elit sit.
                  </p>
                  <div className="row mt-4">
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="p-details-img">
                        <img src={serviceDetails.ssImg1} alt="" />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="p-details-img">
                        <img src={serviceDetails.ssImg2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="service-single-item list-widget">
                  <div className="service-single-title">
                    <h3>Our Capabilities</h3>
                  </div>
                  <p>
                    Massa volutpat odio facilisis purus sit elementum. Non, sed
                    velit dictum quam. Id risus pharetra est, at rhoncus, nec
                    ullamcorper tincidunt. Id aliquet duis sollicitudin diam.
                  </p>
                  <ul>
                    <li>Non saed velit dictum quam risus pharetra esta.</li>
                    <li>
                      Id risus pharetra est, at rhoncus, nec ullamcorper
                      tincidunt.
                    </li>
                    <li>Hac nibh fermentum nisi, platea condimentum cursus.</li>
                    <li>Massa volutpat odio facilisis purus sit elementum.</li>
                    <li>Elit curabitur amet risus bibendum.</li>
                  </ul>
                </div>
                <div className="service-single-item">
                  <div className="service-single-title">
                    <h3>Our approach</h3>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Consequat suspendisse aenean tellus augue morbi risus. Sit
                    morbi vitae morbi sed urna sed purus. Orci facilisi eros sed
                    pellentesque. Risus id sed tortor sed scelerisque.
                    Vestibulum elit elementum, magna id viverra non, velit.
                    Pretium, eros, porttitor fusce auctor vitae id. Phasellus
                    scelerisque nibh eleifend vel enim mauris purus. Rutrum vel
                    sem adipiscing nisi vulputate molestie scelerisque molestie
                    ultrices. Eu, fusce vulputate diam interdum morbi ac a.
                  </p>
                </div>
                <div className="service-single-item list-widget">
                  <div className="service-single-title">
                    <h3>Our Work Process</h3>
                  </div>
                  <ul>
                    <li>Non saed velit dictum quam risus pharetra esta.</li>
                    <li>
                      Id risus pharetra est, at rhoncus, nec ullamcorper
                      tincidunt.
                    </li>
                    <li>Hac nibh fermentum nisi, platea condimentum cursus.</li>
                    <li>Massa volutpat odio facilisis purus sit elementum.</li>
                  </ul>
                </div>
                <div className="service-single-item">
                  <div className="service-single-title">
                    <h3>Related Service</h3>
                  </div>
                  <div className="service-area">
                    <div className="row align-items-center">
                      {Services.slice(0, 3).map((service, srv) => (
                        <div className="col-lg-4 col-md-6 col-12" key={srv}>
                          <div className="service-item">
                            <i>
                              <img src={service.sImg} alt="" />
                            </i>
                            <h2>{service.sTitle}</h2>
                            <p>
                              Lacus, etiam sed est eu tempus need Temer diam
                              congue.
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="service-single-item">
                  <div className="service-contact-area">
                    <div className="contact-title">
                      <h2>Any question? Let's discuss</h2>
                      <p>
                        Get in touch with us to see how we can help you with
                        your project
                      </p>
                    </div>
                    <div className="contact-form-area">
                      <Contact />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ServiceSinglePage;
