import React, { Fragment, useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useParams } from "react-router-dom";
import Contact from "./contact";
import Footer from "../../components/footer/Footer";
import "./style.scss";
import sanityClient from "../../client.js";
import BlockContent from "@sanity/block-content-to-react";

const ProjectDetails = (props) => {
  const [postData, setPostData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,client,land,building,value,location,
          mainImage{
            asset->{
              _id,
              url
             }
           },
           imagesGallery[]{
            asset->{
              _id,
              url
             }
           },
         body,
        
       }`,
        { slug }
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  }, [slug]);
  if (!postData) return <div></div>;
  return (
    <Fragment>
      <Navbar hclass={"header-style-2"} />
      <PageTitle pageTitle={postData.title} pagesub={"Service"} />
      <div className="service-single-area section-padding pt-20">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="service-single-wrap ">
                <div className="row pb-20">
                  <div className="col-lg-7 col-12 pb-20">
                    <div className="service-single-main-img">
                      <img src={postData.mainImage.asset.url} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-5 col-12">
                    <div className="projectDet">
                      <div>
                        <h6>Client</h6>
                        <p>{postData.client}</p>
                      </div>
                      <div>
                        <h6>Land Area</h6>
                        <p>{postData.land}</p>
                      </div>
                      <div>
                        <h6>Building Area</h6>
                        <p>{postData.building}</p>
                      </div>
                      <div>
                        <h6>Value</h6>
                        <p>{postData.value}</p>
                      </div>
                      <div>
                        <h6>Location</h6>
                        <p>{postData.location}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="service-single-item">
                  <div className="service-single-title">
                    <h3>{postData.title}</h3>
                  </div>

                  <BlockContent
                    blocks={postData.body}
                    projectId="n7s6ldm3"
                    dataset="production"
                  />

                  <div className="row mt-4">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="gridColleps">
                        {postData.imagesGallery &&
                          postData.imagesGallery.map((img, ind) => (
                            <div className="p-details-img" key={ind}>
                              <img src={img.asset.url} alt="" />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="service-single-item list-widget">
                  <div className="service-single-title">
                    <h3>Our Capabilities</h3>
                  </div>
                  <p>
                    Massa volutpat odio facilisis purus sit elementum. Non, sed
                    velit dictum quam. Id risus pharetra est, at rhoncus, nec
                    ullamcorper tincidunt. Id aliquet duis sollicitudin diam.
                  </p>
                  <ul>
                    <li>Non saed velit dictum quam risus pharetra esta.</li>
                    <li>
                      Id risus pharetra est, at rhoncus, nec ullamcorper
                      tincidunt.
                    </li>
                    <li>Hac nibh fermentum nisi, platea condimentum cursus.</li>
                    <li>Massa volutpat odio facilisis purus sit elementum.</li>
                    <li>Elit curabitur amet risus bibendum.</li>
                  </ul>
                </div>
                <div className="service-single-item">
                  <div className="service-single-title">
                    <h3>Our approach</h3>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Consequat suspendisse aenean tellus augue morbi risus. Sit
                    morbi vitae morbi sed urna sed purus. Orci facilisi eros sed
                    pellentesque. Risus id sed tortor sed scelerisque.
                    Vestibulum elit elementum, magna id viverra non, velit.
                    Pretium, eros, porttitor fusce auctor vitae id. Phasellus
                    scelerisque nibh eleifend vel enim mauris purus. Rutrum vel
                    sem adipiscing nisi vulputate molestie scelerisque molestie
                    ultrices. Eu, fusce vulputate diam interdum morbi ac a.
                  </p>
                </div>
                <div className="service-single-item list-widget">
                  <div className="service-single-title">
                    <h3>Our Work Process</h3>
                  </div>
                  <ul>
                    <li>Non saed velit dictum quam risus pharetra esta.</li>
                    <li>
                      Id risus pharetra est, at rhoncus, nec ullamcorper
                      tincidunt.
                    </li>
                    <li>Hac nibh fermentum nisi, platea condimentum cursus.</li>
                    <li>Massa volutpat odio facilisis purus sit elementum.</li>
                  </ul>
                </div>

                <div className="service-single-item">
                  <div className="service-contact-area">
                    <div className="contact-title">
                      <h2>Any question? Let's discuss</h2>
                      <p>
                        Get in touch with us to see how we can help you with
                        your project
                      </p>
                    </div>
                    <div className="contact-form-area">
                      <Contact />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ProjectDetails;
