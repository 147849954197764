import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
// import ProjectSectionS2 from "../../components/ProjectSectionS2/ProjectSectionS2";
import Footer from "../../components/footer/Footer";
import ProjectSectionNew from "../../components/ProjectSectionNew/ProjectSectionNew";
import VideoSectionNew from "../../components/VideoSectionNew/VideoSectionNew";

const VideoPage = () => {
  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={"Videos"} pagesub={"Videos"} />
      <VideoSectionNew />
      {/* <ProjectSectionS2 /> */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default VideoPage;
