import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Homepage from "../HomePage/HomePage";
import HomePage2 from "../HomePage2/HomePage2";
import AboutPage from "../AboutPage/AboutPage";
import ServicePage from "../ServicePage/ServicePage";
import ServiceSinglePage from "../ServiceSinglePage/ServiceSinglePage";
import ProjectPage from "../ProjectPage/ProjectPage";
import ProjectSinglePage from "../ProjectSinglePage/ProjectSinglePage";
import BlogPage from "../BlogPage/BlogPage";
import BlogDetails from "../BlogDetails/BlogDetails";
import ContactPage from "../ContactPage/ContactPage";
import ProjectDetails from "../ProjectSinglePage/ProjectDetails";
import CareerPage from "../Career/CareerPage";
import VideoPage from "../VideoPage/VideoPage";
const AllRoute = () => {
  return (
    <div className="App">
      {/* <ScrollToTop /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="home" element={<Homepage />} />
          <Route path="home2" element={<HomePage2 />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="project" element={<ProjectPage />} />
          <Route path="videos" element={<VideoPage />} />
          <Route path="career" element={<CareerPage />} />
          <Route path="project-single/:slug" element={<ProjectSinglePage />} />
          <Route path="project-details/:slug" element={<ProjectDetails />} />
          <Route path="services" element={<ServicePage />} />
          <Route path="service-single/:slug" element={<ServiceSinglePage />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog-single/:slug" element={<BlogDetails />} />
          <Route path="contact" element={<ContactPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
