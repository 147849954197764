import React from "react";
import { Link } from "react-router-dom";
import Services from "../../api/Services";
import Bg from "../../images/services/service-bg.jpg";

const ServiceSection2 = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section
      className="services-section pt-100 rpt-100 pb-70 "
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="container">
        <div className="section-title mb-75 text-center">
          <h2>
            Our Professional <span className="thin"> Services</span>
          </h2>
          <p>
            At SkyHigh Structures, we offer a comprehensive range of services
            tailored to meet all your construction needs. From initial land
            acquisition to the final touches of interior and exterior design, we
            provide end-to-end solutions to ensure your project's success
          </p>
        </div>
        <div className="row">
          {Services.slice(0, 11).map((service, Sitem) => (
            <div className="col-lg-4 col-md-6 col-12" key={Sitem}>
              <div className="service-item">
                <span className="number">{service.Id}</span>
                <img src={service.sImg} alt="Icon" />
                <h3>
                  <Link onClick={ClickHandler} to="#">
                    {service.sTitle}
                  </Link>
                </h3>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceSection2;
