import React from "react";
import ContactForm from "../ContactFrom";
import sIcon1 from "../../images/contact/icon1.png";
import sIcon2 from "../../images/contact/icon2.png";
import sIcon3 from "../../images/contact/icon3.png";

const Contactpage = () => {
  return (
    <div>
      <div className="contact-map" id="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d125329.67687738282!2d76.91093006835938!3d10.997118469666015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba859af2f971cb5%3A0x2fc1c81e183ed282!2sCoimbatore%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1711721542096!5m2!1sen!2sin"></iframe>
      </div>
      <div className="contact-info text-center mb-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="info-item">
                <img src={sIcon1} alt="Icon" />
                <p>20 Broklyn Street, Coimbatore, India</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="info-item">
                <img src={sIcon2} alt="Icon" />
                <p>
                  skyhighstructure@gmail.com <br />
                  +91 9791274632 | +91 8760764239
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="info-item">
                <img src={sIcon3} alt="Icon" />
                <p>
                  Web Address: <br />
                  skyhighstructure.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </div>
  );
};

export default Contactpage;
