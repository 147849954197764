import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import hImg from "../../images/hero.png";
import ban2 from "../../images/ban2.png";
import ban3 from "../../images/ban3.jpg";
import ban4 from "../../images/ban4.jpg";
import ban5 from "../../images/banner_video.mp4";
import ban6 from "../../images/banner_video.webm";

const Hero = () => {
  return (
    <>
      {/* <section
        className="hero-section text-center mt-110 rmt-70"
        style={{ backgroundImage: `url(${ban4})` }}
      >
        <div className="container">
          <div className="hero-content">
            <h1 className="text-bold text-white animate__animated animate__flipInY ">
              Let Your Home Be
            </h1>
            <h1 className="text-lighter animate__animated animate__bounceInDown">
              Unique and Stylish
            </h1>
            <AnchorLink href="#about" className="scroll-down scroll">
              <div className="scroll-box"></div>
            </AnchorLink>
          </div>
        </div>
      </section> */}
      <section className="sectionVid">
        <div className="bg-video">
          <div className="blur-edges"></div>
          <video className="bg-video__content" autoPlay={true} muted loop>
            <source src={ban5} type="video/mp4" />
            <source src={ban6} type="video/webm" />
          </video>
        </div>
        <div className="hero-content">
          <h1 className="text-bold text-white animate__animated animate__flipInY ">
            Let Your Home Be
          </h1>
          <h1 className="text-lighter animate__animated animate__bounceInDown">
            Unique and Stylish
          </h1>
        </div>

        {/* <h1 class="headline">Video Background</h1> */}
      </section>
    </>
  );
};

export default Hero;
