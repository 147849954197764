import React from "react";
import { JackInTheBox, Slide } from "react-awesome-reveal";

const ResturantSec = (props) => {
  return (
    <section className="rastaurant-section mb-100">
      <div className="container-fluid">
        <div className="row">
          <JackInTheBox triggerOnce="true" className="col-lg-6 pr-0">
            <div className="rastaurant-left"></div>
          </JackInTheBox>

          <div className="col-lg-6 pl-0">
            <Slide
              triggerOnce="true"
              className="rastaurant-right pt-240 pb-250 rpt-140 rpb-150"
            >
              <div className="ras-content pl-70">
                <h2 className="border-text">Restaurants</h2>
                <h2 className="text-white">
                  School
                  <br /> Shopping Mall
                  <br /> Hospital
                </h2>
              </div>
            </Slide>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResturantSec;
