import pImg1 from "../images/apartment/apartment1.png";
import pImg2 from "../images/apartment/apartment2.png";
import pImg3 from "../images/apartment/apartment3.png";

import pSImg1 from "../images/apartment/apartment1.png";
import pSImg2 from "../images/apartment/apartment2.png";
import pSImg3 from "../images/apartment/apartment3.png";

import sIcon1 from "../images/apartment/icon1.png";
import sIcon2 from "../images/apartment/icon4.png";
import sIcon3 from "../images/apartment/icon5.png";

const Projects = [
  {
    Id: "2",
    pTitle: "2D and 3D Elevation",
    slug: "Exterior-Design",
    pImg: pImg2,
    pSImg: pSImg2,
    ssIcon: sIcon2,
    des: "Reality with our expertly crafted 2D and 3D visualizations, providing clarity and insight before construction commences.",
    width: "130",
  },
  {
    Id: "1",
    pTitle: "Architectural Design",
    slug: "Architecture",
    pImg: pImg1,
    pSImg: pSImg1,
    ssIcon: sIcon1,
    des: "from the careful study of site, user requirements to the precise execution of the project we do the consultancy work",
    width: "120",
  },
  {
    Id: "3",
    pTitle: "Interior Design",
    slug: "Interior",
    pImg: pImg3,
    pSImg: pSImg3,
    ssIcon: sIcon3,
    des: "We design and correlate the indoor & outdoor spaces with great efficiency to suit the requirements of user.",
    width: "140",
  },
  {
    Id: "4",
    pTitle: "Furniture & Decor",
    slug: "Furniture-&-Decor",
    pImg: pImg1,
    pSImg: pSImg1,
    ssIcon: sIcon1,
    des: "Aenean vehicula non mauris maximus elementum. Nulla facilisi.",
    width: "120",
  },
  {
    Id: "5",
    pTitle: "Exclusive Home Decor",
    slug: "Exclusive-Home-Decor",
    pImg: pImg2,
    pSImg: pSImg2,
    ssIcon: sIcon2,
    des: "Aenean vehicula non mauris maximus elementum. Nulla facilisi.",
    width: "130",
  },
  {
    Id: "6",
    pTitle: "Modern Architecture",
    slug: "Modern-Architecture",
    pImg: pImg3,
    pSImg: pSImg3,
    ssIcon: sIcon3,
    des: "Aenean vehicula non mauris maximus elementum. Nulla facilisi.",
    width: "140",
  },
];

export default Projects;
