import simg from "../images/services/icon1.png";
import simg2 from "../images/services/icon2.png";
import simg3 from "../images/services/icon3.png";
import simg4 from "../images/services/icon4.png";
import simg5 from "../images/services/icon5.png";
import simg6 from "../images/services/icon6.png";
import simg7 from "../images/services/icon7.png";
import simg8 from "../images/services/icon8.png";
import simg9 from "../images/services/icon9.png";
import simg10 from "../images/services/icon10.png";
import simg11 from "../images/services/icon11.png";
import simg12 from "../images/services/icon12.png";
import simg13 from "../images/services/icon13.png";

import sSimg1 from "../images/service-single/img-1.jpg";
import sSimg2 from "../images/service-single/img-2.jpg";
import sSimg3 from "../images/service-single/img-3.jpg";
import sSimg4 from "../images/service-single/img-1.jpg";
import sSimg5 from "../images/service-single/img-2.jpg";
import sSimg6 from "../images/service-single/img-3.jpg";

import sSingleimg1 from "../images/service-single/2.jpg";
import sSingleimg2 from "../images/service-single/3.jpg";

const Services = [
  {
    Id: "01",
    sImg: simg4,
    sSimg: sSimg6,
    sTitle: "2D and 3D Elevation",
    slug: "Concept-Planning",
    description:
      "Our skilled designers create stunning 2D and 3D visualizations of your project, allowing you to visualize and conceptualize your structure before construction begins.",
    des2: "Elementum aliquam byuerirra sagittis duis non quis.",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "02",
    sImg: simg7,
    sSimg: sSimg6,
    sTitle: "Structural Design",
    slug: "Concept-Planning",
    description:
      "Our experienced engineers develop robust structural designs that ensure the safety, stability, and longevity of your building.",
    des2: "Elementum aliquam byuerirra sagittis duis non quis.",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "03",
    sImg: simg3,
    sSimg: sSimg6,
    sTitle: "Architectural Designs",
    slug: "Concept-Planning",
    description:
      "We offer creative and innovative architectural designs that blend functionality with aesthetic appeal, creating spaces that inspire and delight.",
    des2: "Elementum aliquam byuerirra sagittis duis non quis.",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "04",
    sImg: simg5,
    sSimg: sSimg6,
    sTitle: "Interior and Exterior Work",
    slug: "Concept-Planning",
    description:
      "From interior finishes to exterior facades, we provide comprehensive interior and exterior design and construction services to bring your vision to life.",
    des2: "Elementum aliquam byuerirra sagittis duis non quis.",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "05",
    sImg: simg8,
    sSimg: sSimg1,
    sTitle: "Land Purchase Assistance",
    slug: "Design-&-Planning",
    description:
      "We assist you in finding the perfect piece of land for your project, considering factors like location, accessibility, and suitability for construction.",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "06",
    sImg: simg9,
    sSimg: sSimg2,
    sTitle: "Land Registration Work",
    slug: "Custom-Solutions",
    description:
      "Our experts handle all aspects of land registration, ensuring compliance with legal requirements and smooth transfer of ownership.",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "07",
    sImg: simg3,
    sSimg: sSimg3,
    sTitle: "Building Approval Assistance",
    slug: "Furniture-&-Decor",
    description:
      "We guide you through the process of obtaining necessary permits and approvals from relevant authorities, streamlining the approval process for your project.",
    des2: "Elementum aliquam byuerirra sagittis duis non quis.",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "08",
    sImg: simg10,
    sSimg: sSimg4,
    sTitle: "Building Planning",
    slug: "Exterior-Design",
    description:
      "Our team of architects and engineers collaborates with you to create detailed building plans tailored to your specifications and requirements.",
    des2: "Elementum aliquam byuerirra sagittis duis non quis.",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "09",
    sImg: simg11,
    sSimg: sSimg5,
    sTitle: "Electrical Works",
    slug: "Interior-Design",
    description:
      "From wiring to fixture installation, we provide comprehensive electrical services to ensure safe and efficient electrical systems for your structure.",
    des2: "Elementum aliquam byuerirra sagittis duis non quis.",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "10",
    sImg: simg12,
    sSimg: sSimg6,
    sTitle: "Borewell Works",
    slug: "Concept-Planning",
    description:
      "We handle borewell drilling and installation, ensuring access to reliable water sources for your project.",
    des2: "Elementum aliquam byuerirra sagittis duis non quis.",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },

  {
    Id: "11",
    sImg: simg13,
    sSimg: sSimg6,
    sTitle: "Turnkey Solutions",
    slug: "Concept-Planning",
    description:
      "We offer complete, turnkey construction solutions, handling every aspect of your project from start to finish, so you can focus on your vision while we handle the details.",
    des2: "Elementum aliquam byuerirra sagittis duis non quis.",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
];
export default Services;
