import React from "react";
import abimg from "../../images/wp/wp-3.png";

const About2 = (props) => {
  return (
    <section className="about-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="about-image mr-50 rmr-0">
              <div className="experience-year">
                <span className="number">6</span>
                <h4>Years of Experience</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="wp-content  mb-10 ml-10 mr-10 pt-100 rmb-50 mb-pt-50">
              <div className="section-title mb-10 aboutP">
                <h6>SkyHigh Structure</h6>
                <p>
                  SkyHigh Structure seems to be a company established in 2019 by
                  Mr. Gunalan R. It appears to offer a range of services related
                  to land purchase assistance, land registration work, building
                  approval assistance, building planning, electrical works,
                  borewell works, 2D and 3D elevation, structural design,
                  architectural designs, interior and exterior work, as well as
                  turnkey solutions.
                </p>
                <p>
                  From the services listed, it seems that SkyHigh Structure is
                  involved in various aspects of real estate development,
                  construction, and design. They likely assist clients in
                  navigating the complexities of land acquisition, regulatory
                  approvals, architectural design, and construction processes.
                </p>
                <p>
                  If you're considering engaging with SkyHigh Structure for any
                  services, it's always a good idea to research further, review
                  their portfolio, and possibly reach out to them directly for
                  more information on their specific offerings and expertise.
                </p>
                <h2>
                  <span>Vision</span>
                </h2>
                <p>
                  Transforming visions into tangible realities, SkyHigh
                  Structure pioneers innovative solutions in real estate
                  development. Our commitment lies in crafting sustainable,
                  inspiring spaces that enrich lives and communities.
                </p>
                <h2>
                  <span>Mission</span>
                </h2>
                <p>
                  Fostering trust through transparent processes, SkyHigh
                  Structure is dedicated to delivering exceptional quality and
                  value in every project. We strive to exceed expectations,
                  empower clients, and shape a brighter future for all.
                </p>
              </div>
              <img src={abimg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About2;
