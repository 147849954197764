import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MobileMenu from "../MobileMenu/MobileMenu";
import Logo from "../../images/logo.png";

const Header = (props) => {
  const location = useLocation();
  const [menuActive, setMenuState] = useState(false);
  const [SearchActive, setSearchState] = useState(false);

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <header className="main-header">
      <div class="slideBTN">
        <a
          href="https://api.whatsapp.com/send?phone=919791274632&amp;text=Hi, I would like to discuss further on the services offered by your company . Kindly Connect - Thanks."
          target="_blank"
        >
          <i className="fa fa-whatsapp"></i> Whatsapp
        </a>
      </div>
      <div className="header-top">
        <div className="container">
          <div className="top-inner">
            <div className="logo-outer">
              <div className="logo">
                <Link onClick={ClickHandler} to="/">
                  <img src={Logo} alt="Logo" />
                </Link>
              </div>
            </div>

            <ul className="header-info">
              <li>
                <i className="fa fa-map-marker"></i>
                <h6>
                  <span>Address:</span>
                  A: 14/A, Miranda City, Coimbatore - 641025.
                </h6>
              </li>
              <li>
                <i className="fa fa-phone"></i>
                <h6>
                  <span>Call Anytime:</span>
                  +91-9791274632 | +91-8760764239
                </h6>
              </li>
            </ul>
            <div className="languages">
              <div className="containerSocial">
                <div className="social-icons">
                  <div className="social instagram">
                    <div className="tooltip">Instagram</div>
                    <div className="icon socialIcon">
                      <a
                        href="https://www.instagram.com/sky_high_structure/"
                        target="_blank"
                      >
                        <i className="fa-brands fa fa-instagram"></i>
                      </a>
                    </div>
                  </div>

                  <div className="social youtube">
                    <div className="tooltip">YouTube</div>

                    <div className="icon socialIcon">
                      <a
                        href="https://www.youtube.com/@SkyhighStructure"
                        target="_blank"
                      >
                        <i className="fa-brands fa fa-youtube"></i>
                      </a>
                    </div>
                  </div>
                  <div className="social facebook">
                    <div className="tooltip">Facebook</div>
                    <div className="icon socialIcon">
                      <a
                        href="https://www.youtube.com/@SkyhighStructure"
                        target="_blank"
                      >
                        <i className="fa-brands fa fa-facebook-f"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="header-upper">
        <div className="container clearfix">
          <div className="header-inner">
            <div className="nav-outer clearfix">
              <nav className="main-menu navbar-expand-lg">
                <MobileMenu />
                <div className="navbar-collapse collapse clearfix">
                  <ul className="navigation clearfix">
                    <li className={location.pathname === "/" ? "current" : ""}>
                      <Link onClick={ClickHandler} to="/">
                        Home
                      </Link>
                      {/* <ul>
                        <li>
                          <Link onClick={ClickHandler} to="/home">
                            Home Style 1
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/home2">
                            Home Style 2
                          </Link>
                        </li>
                      </ul> */}
                    </li>
                    <li
                      className={
                        location.pathname === "/about" ? "current" : ""
                      }
                    >
                      <Link onClick={ClickHandler} to="/about">
                        about
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === "/project" ? "current" : ""
                      }
                    >
                      <Link onClick={ClickHandler} to="/project">
                        project
                      </Link>
                      {/* <ul>
                        <li>
                          <Link onClick={ClickHandler} to="/property">
                            Project
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to="/project-single/Architecture"
                          >
                            Project Single
                          </Link>
                        </li>
                      </ul> */}
                    </li>
                    <li
                      className={
                        location.pathname === "/services" ? "current" : ""
                      }
                    >
                      <Link onClick={ClickHandler} to="/services">
                        services
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === "/videos" ? "current" : ""
                      }
                    >
                      <Link onClick={ClickHandler} to="/videos">
                        Videos
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === "/career" ? "current" : ""
                      }
                    >
                      <Link onClick={ClickHandler} to="/career">
                        career
                      </Link>
                      {/* <ul>
                        <li>
                          <Link onClick={ClickHandler} to="/blog">
                            Blog
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to="/blog-single/Serverless-Stream-Based-Processing"
                          >
                            Blog Single
                          </Link>
                        </li>
                      </ul> */}
                    </li>
                    <li
                      className={
                        location.pathname === "/contact" ? "current" : ""
                      }
                    >
                      <Link onClick={ClickHandler} to="/contact">
                        Contact us
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>

            <div className="menu-icons">
              {/* <div className="nav-search ml-15">
                <button onClick={() => setSearchState(!SearchActive)}>
                  <i
                    className={`fi ti-search ${
                      SearchActive ? "ti-close" : "fi "
                    }`}
                  ></i>
                </button>
                <form
                  onSubmit={SubmitHandler}
                  className={`hide ${SearchActive ? "show" : ""}`}
                >
                  <input
                    type="text"
                    placeholder="Search"
                    className="searchbox"
                    required=""
                  />
                  <button
                    type="submit"
                    className="searchbutton fa fa-search"
                  ></button>
                </form>
              </div> */}

              <div className="menu-sidebar">
                <button
                  className="enqBtn"
                  onClick={() => setMenuState(!menuActive)}
                >
                  Enquiry
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`sidebar-wrap ${menuActive ? "side-content-visible" : ""}`}
      >
        <div
          className="form-back-drop"
          onClick={() => setMenuState(!menuActive)}
        ></div>
        <section className="hidden-bar">
          <div className="inner-box text-center">
            <div
              className="cross-icon"
              onClick={() => setMenuState(!menuActive)}
            >
              <span className="fa fa-times"></span>
            </div>
            <div className="title">
              <h3>Get Appointment</h3>
            </div>
            <div className="appointment-form">
              <form method="post" onSubmit={SubmitHandler}>
                <div className="form-group">
                  <input
                    type="text"
                    name="text"
                    value=""
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    value=""
                    placeholder="Email Address"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="phone"
                    value=""
                    placeholder="Phone no."
                    required
                  />
                </div>
                <div className="form-group">
                  <textarea placeholder="Message" rows="5"></textarea>
                </div>
                <div className="form-group">
                  <button type="submit" className="theme-btn">
                    Submit now
                  </button>
                </div>
              </form>
            </div>
            <div className="social-icons">
              <a
                href="https://www.instagram.com/sky_high_structure/"
                target="_blank"
              >
                <i className="fa fa-instagram"></i>
              </a>
              <a
                href="https://www.youtube.com/@SkyhighStructure"
                target="_blank"
              >
                <i className="fa fa-youtube"></i>
              </a>
              <Link to="/">
                <i className="fa fa-facebook-f"></i>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </header>
  );
};

export default Header;
