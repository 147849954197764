import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import pimg1 from "../../images/client/1.png";
import pimg2 from "../../images/client/2.png";
import pimg3 from "../../images/client/3.png";
import pimg4 from "../../images/client/4.png";
import pimg5 from "../../images/client/5.png";
import pimg6 from "../../images/client/6.png";
import pimg7 from "../../images/client/7.png";
import pimg8 from "../../images/client/8.png";
import { Link } from "react-router-dom";

const partners = [
  {
    pImg: pimg1,
  },
  {
    pImg: pimg2,
  },
  {
    pImg: pimg3,
  },
  {
    pImg: pimg4,
  },
  {
    pImg: pimg5,
  },
  {
    pImg: pimg6,
  },
  {
    pImg: pimg7,
  },
  {
    pImg: pimg8,
  },
  {
    pImg: pimg3,
  },
];

const PartnerSection = () => {
  var settings = {
    dots: false,
    arrows: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="client-section pb-30 pt-85">
      <div className="container">
        <div className="client-inner">
          <Slider {...settings}>
            {partners.map((partner, pitem) => (
              <div
                className="client-item animate__animated animate__bounceInLeft"
                key={pitem}
              >
                <Link to="/">
                  <img src={partner.pImg} alt="Client" />
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default PartnerSection;
