import React, { useState } from "react";
import fImg from "../../images/faq/faq-right-bg.png";

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

const FaqSection = (props) => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <section className="faq-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 pr-125 rpr-15">
            <div className="faq-left-image"></div>
          </div>
          <div className="col-lg-6 pl-0">
            <div className="faq-accordion ras-content pt-95 pb-110 rpb-0 rmb-100">
              <div className="section-title mb-35">
                <h6>Best Architecture</h6>
                <h2>
                  Architecture did expect
                  <span className="thin">strategist</span>
                </h2>
              </div>
              <div id="accordion">
                <Accordion open={open} toggle={toggle}>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="1" className="card-header">
                      What factors influence the cost of construction?
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      Factors influencing construction costs include the size
                      and complexity of the project, materials used, labor
                      costs, location, site conditions, regulations, and market
                      conditions.
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="2" className="card-header">
                      What are the stages of building construction?
                    </AccordionHeader>
                    <AccordionBody accordionId="2">
                      The stages of building construction typically include
                      planning and design, site preparation, foundation laying,
                      structural framework construction, exterior and interior
                      finishing, and final inspections.
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="3" className="card-header">
                      What are the different types of building construction?
                    </AccordionHeader>
                    <AccordionBody accordionId="3">
                      Building construction can be categorized into various
                      types such as residential construction, commercial
                      construction, industrial construction, infrastructure
                      construction, and specialized construction like bridges,
                      tunnels, and dams.
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="4" className="card-header">
                      How do I choose a reliable construction contractor?
                    </AccordionHeader>
                    <AccordionBody accordionId="4">
                      When selecting a construction contractor, consider factors
                      such as experience, reputation, credentials, past
                      projects, client testimonials, communication skills,
                      adherence to deadlines, and cost estimates.
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
            {/* <div className="faq-right-bg">
                            <img src={fImg} alt="FAQ" />
                        </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
