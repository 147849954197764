import React from "react";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Contact from "../ServiceSinglePage/contact";

const CareerPage = () => {
  return (
    <>
      <Navbar hclass={"header-style-2"} />
      <PageTitle pageTitle={"Career"} pagesub={"Career"} />
      <div className="service-single-area section-padding pt-50 gradientCss">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="service-single-wrap ">
                <div className="service-single-item">
                  <div className="service-contact-area backTran">
                    <div className="contact-title">
                      <h2>Join Our Team</h2>
                      <p>
                        At SkyHigh Structure, we're on a mission to redefine the
                        future of construction with innovative solutions and a
                        commitment to excellence. Join our dynamic team and be
                        part of an exciting journey towards shaping the skyline
                        of tomorrow.
                      </p>
                    </div>
                    <div className="contact-form-area">
                      <Contact />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Scrollbar />
    </>
  );
};

export default CareerPage;
