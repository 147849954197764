import React from "react";
import Pdf from "../../images/specification for skyhigh structure.pdf";

const CtaSection = (props) => {
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <section className="call-action">
      <div className="container">
        <div className="call-action-inner">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="section-title text-white rmb-35">
                <h2>SkyHigh Structure</h2>
                <p className="pdfP">
                  Unlock the visionary potential of your construction projects
                  with SkyHigh Structure, the premier building construction
                  company, and access our detailed specification document for
                  unparalleled excellence.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <form onSubmit={SubmitHandler} className="subscribe">
                <a href={Pdf} target="_blank" className="theme-btn style-two">
                  <i className="fa fa-download"></i> Specification
                </a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaSection;
