import React, { Fragment, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { Link, useLocation } from "react-router-dom";
import "./style.css";

const menus = [
  {
    id: 1,
    title: "Home",
    link: "/home",
    // submenu: [
    //     {
    //         id: 11,
    //         title: 'Home style 1',
    //         link: '/home'
    //     },
    //     {
    //         id: 12,
    //         title: 'Home style 2',
    //         link: '/home2'
    //     }
    // ]
  },

  {
    id: 3,
    title: "about",
    link: "/about",
  },

  {
    id: 6,
    title: "Projects",
    link: "/project",
    // submenu: [
    //     {
    //         id: 61,
    //         title: 'Project',
    //         link: '/property'
    //     },
    //     {
    //         id: 62,
    //         title: 'Project Single',
    //         link: '/project-single/Architecture'
    //     }
    // ]
  },
  {
    id: 7,
    title: "Service",
    link: "/services",
    // submenu: [
    //     {
    //         id: 71,
    //         title: 'Service',
    //         link: '/services'
    //     },
    //     {
    //         id: 72,
    //         title: 'Service Single',
    //         link: '/service-single/Design-&-Planning'
    //     },
    // ]
  },
  {
    id: 5,
    title: "Career",
    link: "/career",
    // submenu: [
    //     {
    //         id: 51,
    //         title: 'Blog',
    //         link: '/blog'
    //     },
    //     {
    //         id: 52,
    //         title: 'Blog single',
    //         link: '/blog-single/Serverless-Stream-Based-Processing'
    //     }
    // ]
  },
  {
    id: 88,
    title: "Contact",
    link: "/contact",
  },
];

const MobileMenu = () => {
  const location = useLocation();
  const [openId, setOpenId] = useState(0);
  const [menuActive, setMenuState] = useState(false);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div>
      <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
        <div className="menu-close">
          <div className="close" onClick={() => setMenuState(!menuActive)}>
            <i className="ti-close"></i>
          </div>
        </div>

        <ul className="responsivemenu">
          {menus.map((item, mn) => {
            return (
              <ListItem
                className={item.id === openId ? "active" : null}
                key={mn}
              >
                {item.submenu ? (
                  <Fragment>
                    <p
                      onClick={() =>
                        setOpenId(item.id === openId ? 0 : item.id)
                      }
                    >
                      {item.title}
                      <i
                        className={
                          item.id === openId
                            ? "fa fa-angle-up"
                            : "fa fa-angle-down"
                        }
                      ></i>
                    </p>
                    <Collapse
                      in={item.id === openId}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List className="subMenu">
                        <Fragment>
                          {item.submenu.map((submenu, i) => {
                            return (
                              <ListItem key={i}>
                                <Link
                                  className={
                                    location.pathname === submenu.link
                                      ? "current active"
                                      : "active"
                                  }
                                  onClick={ClickHandler}
                                  to={submenu.link}
                                >
                                  {submenu.title}
                                </Link>
                              </ListItem>
                            );
                          })}
                        </Fragment>
                      </List>
                    </Collapse>
                  </Fragment>
                ) : (
                  <Link
                    className={
                      location.pathname === item.link
                        ? "blueClr active"
                        : "active"
                    }
                    to={item.link}
                  >
                    {item.title}
                  </Link>
                )}
              </ListItem>
            );
          })}
        </ul>
      </div>

      <div className="navbar-header clearfix">
        <button
          type="button"
          className="navbar-toggle"
          onClick={() => setMenuState(!menuActive)}
        >
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
      </div>
    </div>
  );
};

export default MobileMenu;
