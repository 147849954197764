import React from "react";
import Projects from "../../api/Projects";
import { Link } from "react-router-dom";

const ProjectSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="apartment-section text-center mb-50 mb-pt-0 pt-185">
      <div className="container">
        <div className="section-title mb-75">
          <h2>
            Our Professional <span className="thin"> Services</span>
          </h2>
          <p>Advanced Structural Engineering and Construction</p>
        </div>
        <div className="apartment-carousel">
          <div className="row">
            {Projects.slice(0, 3).map((project, i) => (
              <div className="col col-lg-4 col-md-6 col-12" key={i}>
                <div className="apartment-item">
                  <div className="apartment-img">
                    <img src={project.pImg} alt="Apartment" />
                  </div>
                  <div className="apartment-content">
                    <img src={project.ssIcon} alt="Icon" />
                    <h3>
                      <Link onClick={ClickHandler} to={`/services`}>
                        {project.pTitle}
                      </Link>
                    </h3>
                    {/* <span>from {project.width} m2</span> */}
                    <p>{project.des} </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectSection;
