import React, { useState } from "react";
// import SimpleReactValidator from "simple-react-validator";

const Contact = () => {
  const submitHandler = (e) => {
    e.preventDefault();
    alert(
      "Thank you for submitting your career form! Your application has been successfully received. We'll review it carefully and contact you soon regarding the next steps."
    );
  };

  return (
    <form
      onSubmit={(e) => submitHandler(e)}
      className="contact-validation-active"
    >
      <div className="row">
        <div className="col col-lg-6 col-md-6 col-12">
          <div className="form-field">
            <input
              className="form-control"
              type="text"
              name="name"
              placeholder="Your Name"
              required={true}
            />
          </div>
        </div>
        <div className="col col-lg-6 col-md-6 col-12">
          <div className="form-field">
            <input
              className="form-control"
              type="email"
              name="email"
              required={true}
              placeholder="Your Email"
            />
          </div>
        </div>
        <div className="col col-lg-6 col-md-6 col-12">
          <div className="form-field">
            <input
              className="form-control"
              type="number"
              name="phone"
              required={true}
              placeholder="Your phone no"
            />
          </div>
        </div>
        <div className="col col-lg-6 col-md-6 col-12">
          <div className="form-field">
            <input
              className="form-control"
              type="text"
              name="degree"
              required={true}
              placeholder="Your Degree"
            />
          </div>
        </div>
        <div className="col col-lg-6 col-md-6 col-12">
          <div className="form-field">
            <input
              className="form-control"
              type="text"
              name="experience"
              required={true}
              placeholder="Your experience (Ex: fresher or 1 years)"
            />
          </div>
        </div>
        <div className="col col-lg-6 col-md-6 col-12">
          <div className="form-field">
            <input
              className="form-control"
              type="text"
              name="year"
              required={true}
              placeholder="College Completed year"
            />
          </div>
        </div>
        <div className="col col-lg-6 col-md-6 col-12">
          <div className="form-field">
            <input
              className="form-control"
              type="text"
              name="city"
              required={true}
              placeholder="your city"
            />
          </div>
        </div>
        <div className="col col-lg-6 col-md-6 col-12">
          <div className="form-field">
            <input
              className="form-control"
              type="text"
              name="state"
              required={true}
              placeholder="Your state"
            />
          </div>
        </div>

        <div className="col fullwidth col-lg-12">
          <textarea
            className="form-control"
            type="text"
            name="skill"
            placeholder="Your Skill"
            required={true}
          ></textarea>
        </div>
      </div>
      <div className="submit-area">
        <button type="submit" className="theme-btn">
          {" "}
          Submit Now
        </button>
      </div>
    </form>
  );
};

export default Contact;
