import React, { useEffect, useState } from "react";
// import Projects from "../../api/Projects";
import { Link } from "react-router-dom";
import Bg from "../../images/apartment/apartment1.png";
import Bg2 from "../../images/apartment/apartment2.png";
import Bg3 from "../../images/apartment/apartment3.png";
import sanityClient from "../../client";
// import BlockContent from "@sanity/block-content-to-react";

const VideoSectionNew = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const [allPostsData, setAllPosts] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "video"]{
        title,
        link,        
        mainImage{
          asset->{
          _id,
          url
        }
      },
      body
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);
  if (!allPostsData) {
    return <>Loading...</>;
  }

  return (
    <section className="apartment-section pt-50  text-center">
      <div className="section-title mb-0">
        <h2>
          Explore our finest <span className="thin">Videos</span>
        </h2>
        <p>
          " Quality is never an accident; it is always the result of high
          intention, sincere effort, intelligent direction, and skillful
          execution "
        </p>
      </div>

      <section className="cards-wrapper ">
        {allPostsData &&
          allPostsData.map((post, index) => (
            <div className="card-grid-space" key={index}>
              <Link
                className="cardNew cardVideo"
                target="_blank"
                to={post.link}
                style={{
                  backgroundImage: `linear-gradient(45deg, #006bb624, #0000004f),url(${post.mainImage.asset.url})`,
                }}
              >
                <div>
                  <h2>{post.title}</h2>
                  <p>
                    {/* <BlockContent
                      blocks={post.body}
                      projectId="n7s6ldm3"
                      dataset="production"
                    /> */}
                  </p>
                  <div className="date">
                    <i className="fa fa-play-circle"></i> {post.client}
                  </div>
                  <div className="tags">
                    <div className="tag">Play video</div>
                  </div>
                </div>
              </Link>
            </div>
          ))}

        {/* https://images.unsplash.com/photo-1520839090488-4a6c211e2f94?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=38951b8650067840307cba514b554ba5&auto=format&fit=crop&w=1350&q=80 */}
      </section>
    </section>
  );
};

export default VideoSectionNew;
